import { throwError, Observable, from } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { AppSettings } from '../app.settings';
import { catchError, flatMap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DI } from '../state/core';
import { DataPlateService } from '../dataplate/dataplate.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorShared } from '../error/ErrorShared';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';

@Injectable()
export class PartApiService extends DI.AutoDependencyInjector {
    public baseUrl: string;

    @DI.Inject(() => DataPlateService)
      private dataplateservice: DataPlateService;

      @DI.Inject(() => HttpClient)
      private http: HttpClient; 

      @DI.Inject(() => Router)
    private router: Router;

    @DI.Inject(() => ErrorShared)
    private error: ErrorShared;

    @DI.Inject(() => SharedInfoService)
    private sharedInfoService: SharedInfoService;

    private datePipe: DatePipe = new DatePipe('en-US');

    constructor(_injector: Injector) {
        super(_injector);
        //this.baseUrl = 'src/app/systemView/bb1775-02.json';
        this.baseUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/optionDetails?`;
    }
     public fetchPartDetails(path: string): Observable<any> {
        return this.http.get(this.getUrl(path)).
            pipe(map((response:Response) => response)).pipe(catchError(this.handleError));
        //.map((response) => response)
        //.catch(this.handleError);
        // .do((data) => console.log('ALL : ' + JSON.stringify(data)))
    }

    public getKitDetails(kit: any): Observable<any> {
        return from(this.dataplateservice.isRestructureFlagEnabled(kit.esnType)).pipe(
            flatMap(flag => {
                if (flag) {
                    return this.fetchComponentDetails(kit);
                }

                const path = 'type=kits_options&optionNo=' + kit.kitNo;
                return this.fetchPartDetails(path)
            })
        );
    }

    public getOptionDetails(option: any, isPrecheck: boolean, errorCallbak: (error: Error) => void) {
        return from(this.dataplateservice.isRestructureFlagEnabled(option.esnType)).pipe(
            flatMap(flag => {
                let res = this.sharedInfoService.getEffectiveESNInfo();
                if (flag) {
                    return this.fetchComponentDetails(option, res.esn);
                }

                let optionNo = option.optionNo;
                let mbom = res.mbom;
                let filmCard = option.filmCard;
                let specGroup = option.specGroup;
                let optionName = option.optionName;
                let optionNoTrim = (optionNo ? optionNo : "").trim();
                let engineBuildDate = null;
                let enginePlantCode = option.plantCode;
                let isPEOption = option.isPEOption;
                let type = option.optionType ? option.optionType : "";
                let isRussiaBlock = (optionName && (optionName.toUpperCase() === 'BLOCK,LONG' || optionName.toUpperCase() === 'BLOCK,SHORT')) ? true : false;

                const getEngineType = () => {
                    switch (mbom) {
                        case "mbom": return "mbom_";
                        case "recon": return "rec_";
                        case "jv": return "jv_";
                        case "package": return "package_";
                        case "npbu": return "npbu_";
                        default: return "";
                    }
                };

                if (isPEOption || isRussiaBlock)
                    type = "pe_options";
                else if (!type || type === "options") {
                    type = getEngineType() + "options";
                }
                try {
                    enginePlantCode = enginePlantCode || res.enginePlantCode;
                    if (option.buildDate != 'null') {
                        engineBuildDate = option.buildDate || '';
                        engineBuildDate = (
                            this.datePipe
                                .transform(engineBuildDate.replace(/T?(\d{1,2}\:\d{1,2}(\:\d{1,2}(\.\d{1,3}Z?)?)?)/, ''), 'dd-MMM-yy') || ''
                        ).toLowerCase();
                    }
                } catch (error) {
                    errorCallbak(error)
                }
                let path = `optionNo=${optionNoTrim}&type=${type.trim()}`;
                if (type !== "pe_options")
                    path += `&plantCode=${(enginePlantCode ? enginePlantCode : "").trim()}`;

                if (optionNoTrim.toUpperCase().startsWith('PE') && (optionName.toUpperCase().indexOf('AFTERTREATME') !== -1) && res.esn) {
                    path += `&serialNumber=${res.esn}`;
                }

                if (type === "options") {
                    let precheck = engineBuildDate ? new Date(engineBuildDate).getFullYear() : 2014;
                    if (precheck >= 2015) {
                        if (isPrecheck) { path = path.replace('options', 'mbom_options') };
                        if ((enginePlantCode === '0' && isPrecheck) || !isPrecheck) {
                            path += `&effectiveDate=${(engineBuildDate ? engineBuildDate : "").trim()}`;
                        }
                    } else {
                        path += `&filmCard=${(filmCard ? filmCard : "").trim()}&specGroup=${(specGroup ? specGroup : "").trim()}`;
                    }
                } else if (type === "jv_options" || type === 'package_options' || (type === 'mbom_options' && enginePlantCode === '0')) {
                    path += `&effectiveDate=${(engineBuildDate ? engineBuildDate : "").trim()}`;
                }
                return this.fetchPartDetails(path)
            })
        );
    }

    private fetchComponentDetails(component: any, esn?: string): Observable<any> {
        const plantCode = component.plantCode || 'XXX';
        const baseUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/v2/componentDetails/${component.componentType}`;
        const queryParams = {
            esnType: component.esnType,
            componentHash: component.componentHash
        };

        if (esn) {
            queryParams['esn'] = esn;
        }

        if (component.specGroup) {
            queryParams['specGroup'] = component.specGroup;
        }

        if (plantCode.padStart(3, '0') === '000' && component.buildDate) {
            queryParams['buildDate'] = component.buildDate;
        }

        return this.http.get(baseUrl, { params: queryParams }).
            pipe(map((response: Response) => response)).pipe(catchError(this.handleError));
    }

    private getUrl(path: string): string {
        return `${this.baseUrl}${path}`;
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.message}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
